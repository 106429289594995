import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

// ** MUI Imports
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** Custom Components
import FeaturedContentSlider from 'components/collectibles/FeaturedContentSlider'
import FeaturedContentSliderMobile from 'components/collectibles/FeaturedContentSliderMobile'
import CollectibleFilter from 'components/collectibles/CollectibleFilter'
import CollectibleSlider from 'components/collectibles/CollectibleSlider'
import CollectibleCardWithImage from 'components/collectibles/CollectibleCardWithImage'
import CollectibleCardWithImageSkeleton from 'components/collectibles/CollectibleCardWithImageSkeleton'
import EmptyState from 'layouts/components/EmptyState'

import FilterList from '@mui/icons-material/FilterList'

import moment from 'moment'
import FirstTimeModal from 'components/collectibles/FirstTimeModal'
import { useCollectiblesFilteredByStatusQuery } from 'data/rewards/query'
import { Status } from 'utils/apiv2/types'
import { useAuth } from '@1kit/ui/context/auth'
import { RewardTier } from 'types/RewardTier'

function Collectibles() {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const router = useRouter()
  const { user } = useAuth()
  // const statuses = useMemo(() => [Status.LIVE], [])
  const {
    data,
    ownedByUser: roleSpecificCollectibles,
    isLoading: loading,
  } = useCollectiblesFilteredByStatusQuery([Status.LIVE], user)
  const [showMobileFilter, setShowMobileFilter] = useState(false)
  const [showFirstTimeModal, setShowFirstTimeModal] = useState(false)
  const premiumCollectibles = data[RewardTier.PREMIUM]
  const collectibles = data[RewardTier.BASIC]

  const [filter, setFilter] = useState({
    searchText: '',
    gameGenre: 'all',
    category: 'all',
  })
  useEffect(() => {
    handleFirstTimeModal()
    if (collectibles && collectibles.length) {
      findAvailableCollectibles(collectibles)
    }
  }, [collectibles])

  const handleFirstTimeModal = () => {
    if (!window.localStorage.getItem('first-time-modal')) {
      setShowFirstTimeModal(true)
    }
    window.localStorage.setItem('first-time-modal', 'true')
  }

  const findAvailableCollectibles = (allCollectibles) => {
    const availableCollectibles = allCollectibles.filter((collectible) => {
      const dueDays = moment(collectible.end_date).diff(moment(collectible.start_date), 'd')

      if (dueDays < 0) return false

      return true
    })
  }

  const onClickCollectible = (collectible) => {
    router.push(`/collectibles/${collectible.id}`)
  }

  const searchConditionResult = (item) => {
    let result = false
    if (item.title) {
      if (item.title.toLowerCase().indexOf(filter.searchText.toLowerCase()) !== -1) {
        result = true
      }
    }
    if (item.game?.title) {
      if (item.game.title.toLowerCase().indexOf(filter.searchText.toLowerCase()) !== -1) {
        result = true
      }
    }
    return result
  }

  const filteredCollectibles = collectibles?.filter((o) => {
    if (filter.gameGenre !== 'all') {
      if (o.game?.genres.indexOf(filter.gameGenre) === -1) return false
    }
    return searchConditionResult(o)
  }) ?? []

  const filteredEndingSoon = collectibles?.filter((o) => {
    if (!o.end_date || moment(o.end_date).diff(moment(), 'd') > 7) return false

    if (filter.gameGenre !== 'all') {
      if (o.game?.genres.indexOf(filter.gameGenre) === -1) return false
    }
    return searchConditionResult(o)
  }) ?? []

  const filteredNewCollectibles = collectibles?.filter((o) => {
    if (!o.start_date || moment().diff(moment(o.start_date), 'd') > 7) return false

    if (filter.gameGenre !== 'all') {
      if (o.game?.genres.indexOf(filter.gameGenre) === -1) return false
    }
    return searchConditionResult(o)
  }) ?? []

  const filteredRoleSpecificCollectibles = roleSpecificCollectibles?.filter(searchConditionResult)
  ?? []
  const filteredPremiumCollectibles = premiumCollectibles?.filter(searchConditionResult) ?? []

  const featuredRewards = collectibles.filter((c) => c.featured).map((c) => ({
    id: c.id,
    title: c.game?.title,
    description: c.name,
    image: c.assets?.cardMedia?.defaultMedia?.src1x,
    thumbnail: c.assets?.cardMedia?.defaultMedia?.src1x,
  })).slice(0, 5)

  return (
    <div>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {mobile
            ? <FeaturedContentSliderMobile featuredRewards={featuredRewards} />
            : <FeaturedContentSlider featuredRewards={featuredRewards} />}
        </Grid>
        {!mobile && (
          <Grid item xs={12}>
            <CollectibleFilter filter={filter} setFilter={setFilter} />
          </Grid>
        )}
        <Grid item xs={12}>
          {filteredRoleSpecificCollectibles.length > 0 && (
            <Box sx={{ pt: 4, pb: 6, margin: '0 auto' }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 400, color: 'white', mb: 2, mx: mobile ? 0 : 10,
                }}
              >
                {'Owned by you (Only visible to you - not LIVE)'}
              </Typography>
              <CollectibleSlider
                collectibles={filteredRoleSpecificCollectibles}
                onClickCollectible={onClickCollectible}
              />
            </Box>
          )}
          {filteredPremiumCollectibles.length > 0 && (
            <Box sx={{ pt: 4, pb: 6, margin: '0 auto' }}>
              <Box display="flex" alignItems="center">
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 400, color: 'white', mb: 2, mx: mobile ? 0 : 10, mr: 2,
                  }}
                >
                  {'1Pass Exclusives'}
                </Typography>
                <Typography variant="overline">
                  { '(Subscribe for access)'}
                </Typography>
              </Box>
              <CollectibleSlider
                collectibles={filteredPremiumCollectibles}
                onClickCollectible={onClickCollectible}
              />
            </Box>
          )}
          {filteredEndingSoon.length > 0 && (filter.category === 'all' || filter.category === 'expiring-soon') && (
            <Box sx={{ pt: 4, pb: 6, margin: '0 auto' }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 400, color: 'white', mb: 2, mx: mobile ? 0 : 10,
                }}
              >
                {'Ending soon'}
              </Typography>
              <CollectibleSlider
                collectibles={filteredEndingSoon}
                onClickCollectible={onClickCollectible}
              />
            </Box>
          )}
          {filteredNewCollectibles.length > 0 && (filter.category === 'all' || filter.category === 'new-collectibles') && (
            <Box sx={{ pt: 4, pb: 6, margin: '0 auto' }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 400, color: 'white', mb: 2, mx: mobile ? 0 : 10,
                }}
              >
                {'New Rewards'}
              </Typography>
              <CollectibleSlider
                collectibles={filteredNewCollectibles}
                onClickCollectible={onClickCollectible}
              />
            </Box>
          )}
          {filter.category === 'all' && (
            <Box sx={{ px: mobile ? 0 : 10, py: 4, margin: '0 auto' }}>
              <Typography variant="h5" sx={{ fontWeight: 400, color: 'white', mb: 2 }}>
                {'Rewards'}
              </Typography>
              {
                (filteredCollectibles.length === 0 && !loading)
                && <EmptyState />
              }
              {mobile && (
                <CollectibleSlider
                  collectibles={filteredCollectibles}
                  onClickCollectible={onClickCollectible}
                />
              )}
              {!mobile && (
                <Grid container spacing={4}>
                  {loading
                    && [...Array(6)].map((_, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid key={index} item xs={12} md={4}>
                        <CollectibleCardWithImageSkeleton />
                      </Grid>
                    ))}
                  {!loading
                    && filteredCollectibles.map((collectible) => (
                      <Grid key={collectible.id} item xs={12} md={4}>
                        <CollectibleCardWithImage
                          collectible={collectible}
                          onClickCollectible={onClickCollectible}
                        />
                      </Grid>
                    ))}
                </Grid>
              )}
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Button
              variant="outlined"
              sx={{ mr: 2 }}
              onClick={() => { window.open('https://gr1d.network/ecosystem', '_blank') }}
            >
              {'browse upcoming games'}
            </Button>
          </Box>
        </Grid>
        {mobile && (
          <>
            {!showMobileFilter && (
              <Box
                sx={{
                  position: 'fixed',
                  top: '80vh',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 10,
                  backgroundColor: 'customColors.darkBg',
                  py: 3,
                  px: 4,
                  borderRadius: 1,
                  border: '1px solid rgba(234, 234, 255, 0.26)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                style={{ top: '85dvh' }}
                onClick={() => setShowMobileFilter(true)}
              >
                <FilterList fontSize="small" sx={{ mr: 3 }} />
                {' '}
                {'Filters'}
              </Box>
            )}
            <Modal open={showMobileFilter} onClose={() => setShowMobileFilter(false)}>
              <Box
                sx={{
                  position: 'absolute',
                  top: '30%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '90%',
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                  boxShadow: '0px 2px 10px rgba(20, 21, 33, 0.18)',
                  py: 2,
                }}
              >
                <CollectibleFilter mobile={mobile} filter={filter} setFilter={setFilter} />
                <Button variant="text" fullWidth onClick={() => setShowMobileFilter(false)}>
                  <Typography variant="subtitle1" sx={{ color: 'text.secondary' }}>
                    {'Close'}
                  </Typography>
                </Button>
              </Box>
            </Modal>
          </>
        )}
        <FirstTimeModal onClose={() => setShowFirstTimeModal(false)} open={showFirstTimeModal} />
      </Grid>
    </div>
  )
}

export default Collectibles
