// ** Next Imports
import Head from 'next/head'
import { Router } from 'next/router'
import { CommonProvider } from '@1kit/ui/context/common'

import 'core-js/actual/object/has-own'

// ** Store Imports
import { store } from 'store'
import { Provider } from 'react-redux'

// ** Loader Import
import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'

// ** Config Imports
import 'configs/i18n'
import themeConfig from 'configs/themeConfig'

// ** Third Party Import
import { Toaster } from 'react-hot-toast'

// ** Component Imports
import UserLayout from 'layouts/UserLayout'
import Guard from 'components/auth/Guard'

import WindowWrapper from 'components/WindowWrapper'

import { ErrorBoundary } from '@1kit/ui/components/ErrorBoundary'

import ReactHotToast from 'materialize/styles/libs/react-hot-toast'
import { SettingsProvider, SettingsConsumer } from 'materialize/context/settingsContext'
import ThemeComponent from 'materialize/theme/ThemeComponent'

// ** Utils Imports
import { createEmotionCache } from 'materialize/utils/create-emotion-cache'

// ** Prismjs Styles
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** Global css styles
import '../../styles/globals.css'
import DevkinToolsOverlay, { useDevkinWindowTools } from '@1kit/ui/devkin'

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

// ** Configure JSS & ClassName
function App(props) {
  useDevkinWindowTools()
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  // Variables
  const getLayout = Component.getLayout ?? ((page) => <UserLayout>{page}</UserLayout>)

  return (
    <ErrorBoundary>
      <CommonProvider>
        <DevkinToolsOverlay />
        <Provider store={store}>
          <CacheProvider value={emotionCache}>
            <Head>
              <title>{'GR1D Terminal: Discover games and claim digital collectibles.'}</title>
              <meta
                name="description"
                content="GR1D Terminal is a content discovery destination for gamers.  Claim free digital collectibles and join the community."
              />

              <meta content="GR1D Terminal: Discover games and claim digital collectibles." key="og:title" property="og:title" />
              <meta
                content="GR1D Terminal is a content discovery destination for gamers.  Claim free digital collectibles and join the community."
                key="og:description"
                property="og:description"
              />
              <meta
                content={`${process.env.NEXT_PUBLIC_APP_URL}/images/opengraph-image.png`}
                key="og:image"
                property="og:image"
              />

              <meta name="keywords" content="1Kin Labs, 1Kin, Digital Collectibles, GR1D, Terminal, web3, games" />
              <meta name="viewport" content="initial-scale=1, width=device-width" />
            </Head>

            <WindowWrapper>
              <SettingsProvider pageSettings={null}>
                <SettingsConsumer>
                  {({ settings }) => (
                    <ThemeComponent settings={settings}>
                      <Guard component={Component}>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        {getLayout?.(<Component {...pageProps} />)}
                      </Guard>
                    </ThemeComponent>
                  )}
                </SettingsConsumer>
              </SettingsProvider>
            </WindowWrapper>
            <ReactHotToast>
              <Toaster position="top-right" toastOptions={{ className: 'react-hot-toast' }} />
            </ReactHotToast>
          </CacheProvider>
        </Provider>
      </CommonProvider>
    </ErrorBoundary>
  )
}

export default App
