import { useMutation, useQueryClient } from '@1kit/ui/data'
import { useInvalidateCurrentUser } from '@1kit/ui/data/user'
import { useFlags } from '@1kit/ui/flags'
import { startRewardClaimAsClaimV1 } from 'utils/apiv2/startRewardClaim'

export function useClaimRewardMutation(id: number | undefined) {
  const client = useQueryClient()
  const flags = useFlags()
  const invalidateCurrentUser = useInvalidateCurrentUser()
  return useMutation({
    mutationKey: ['/rewards/claim', id],
    async onSuccess() {
      await client.invalidateQueries({ queryKey: ['/rewards', id, flags] })
      await client.invalidateQueries({ queryKey: ['/rewards', flags] })
      invalidateCurrentUser()
    },
    async mutationFn() {
      if (id) {
        return startRewardClaimAsClaimV1(id)
      }
      return undefined
    },
  })
}
