import { RewardTier } from 'types/RewardTier'

export enum Platform {
  PC = 'PC',
  PS4 = 'PS4',
  PS5 = 'PS5',
  XboxOne = 'Xbox One',
  XboxSeriesX = 'Xbox Series X',
  Switch = 'Switch',
  iOS = 'iOS',
  PlayStation_5 = 'PlayStation 5',
  Amazon_Luna = 'Amazon Luna',
  Nintendo_Switch = 'Nintendo Switch',
  PlayStation_4 = 'PlayStation 4',
  Web_Browser = 'Web Browser',
  MAC = 'MAC',
  Steam = 'Steam',
  Mac = 'Mac',
  Android = 'Android',
  Epic_Game_Store = 'Epic Game Store',
  Playstation = 'Playstation',
  Xbox_Cloud_Gaming = 'Xbox Cloud Gaming',
  Xbox = 'Xbox',
}
export enum Genre {
  SIMULATOR = 'SIMULATOR',
  ROLE_PLAYING = 'ROLE_PLAYING',
  Indie = 'Indie',
  Arcade = 'Arcade',
  Action = 'Action',
  Platformer = 'Platformer',
  Puzzles = 'Puzzles',
  MOBA = 'MOBA',
  MUSIC = 'MUSIC',
  ADVENTURE = 'ADVENTURE',
  TACTICAL = 'TACTICAL',
  HACK_AND_SLASH_BEAT_EM_UP = 'HACK_AND_SLASH_BEAT_EM_UP',
  Retro = 'Retro & Nostalgia',
  RPG = 'RPG',
  SHOOTER = 'SHOOTER',
  Casual = 'Casual',
  STRATEGY = 'STRATEGY',
  SPORT = 'SPORT',
  FPS = 'FPS',
}
export enum GameMode {
  MULTIPLAYER = 'MULTIPLAYER',
  MASSIVELY_MULTIPLAYER_ONLINE = 'MASSIVELY_MULTIPLAYER_ONLINE',
  SINGLE_PLAYER = 'SINGLE_PLAYER',
  CO_OPERATIVE = 'CO_OPERATIVE',
}
export type FAQ = { question: string, answer: string }

export enum ClaimMethod {
  CODE_AUTO = 'code_auto',
  CODE_MANUAL = 'code_manual'
}

export enum Status {
  LIVE = 'Live',
  SOLD_OUT = 'Sold Out',
  EXPIRED = 'Expired',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  SUBMITTED = 'Submitted',
  DRAFT = 'Draft',
  ARCHIVED = 'Archived'
}

export type Reward = {
  id: number
  legacyId: number | null
  name: string
  description: string
  faqList: FAQ[] | null
  createdAt: Date
  updatedAt: Date | null
  deletedAt: Date | null
  startDate: Date | null
  endDate: Date | null
  updatedBy: number | null
  submittedAt: Date | null
  approvedAt: Date | null
  rejectedAt: Date | null
  gameId: number
  itemDetails: string[] | null
  featuredImage: string
  isFeatured: boolean
  additionalMedia: string[] | null
  claimInstructions: string | null
  claimMethod: string
  claimLimit: number
  totalClaimed: number
  isAutoCompleted: boolean
  claim?: Claim
  status: Status
  tier: RewardTier
}

export type Game = {
  id: number
  name: string
  description: string
  platforms?: Platform[] | null
  genres?: Genre[] | null
  gameModes?: GameMode[] | null
  publisher: string
  minAge?: number | null
  releaseDate?: Date | null
  faqList?: FAQ[] | null
  website?: string | null
  email?: string | null
  url?: string | null
  coverImage: string
  legacyId?: number | null
  legacy: unknown
  createdAt: Date
  updatedAt?: Date | null
  deletedAt?: Date | null
  accountId: number
  startDate?: Date | null
  endDate?: Date | null
  updatedBy?: number | null
  submittedAt?: Date | null
  approvedAt?: Date | null
  rejectedAt?: Date | null
  liveRewardCount: number
  totalRewardCount: number
  claimedRewardCount: number
  liveClaimedRewardCount: number
  status: Status
}

export enum ClaimStatus {
  AVAILABLE = 'Available',
  IN_PROGRESS = 'In Progress',
  COMPLETED = 'Completed'
}

export type Claim = {
  id: number
  createdAt: Date
  updatedAt: Date | null
  accountId: number
  gameId: number
  userId: number | null
  rewardId: number
  status: ClaimStatus
  code: string
}
