'use client'

import CallbackPage from 'components/CallbackPage'
import { useAuthCallback } from '@1kit/ui/data/auth'
import { useSearchParams } from 'next/navigation'

export default function AuthCallback() {
  const searchParams = useSearchParams()
  const code = searchParams.get('code') || ''
  const state = searchParams.get('state') || ''
  const result = useAuthCallback(code, state)

  return (
    <CallbackPage result={result} />
  )
}
