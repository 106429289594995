import { styled } from '@1kit/ui/theme'
import { Button, DialogActions, Typography } from '@mui/material'
import Dialog, { Props as DialogProps } from '@1kit/ui/components/Dialog'
import { useAuth } from '@1kit/ui/context/auth'
import { CollectibleBlopBlop } from 'utils/apiv2/transformations'
import { RewardTier } from 'types/RewardTier'
import { OnePassPlans } from './one-pass/OnePassPlans'

const OnePassDialogActions = styled(DialogActions)`
  place-content: center;
`

export type Props = DialogProps & {
  className?: string,
  collectible?: CollectibleBlopBlop
}

const PremiumRewardContainer = styled.div``
const Container = styled.div``

const OnePassDialog = styled(({
  className, open, onClose, collectible,
} : Props) => {
  const { user } = useAuth()
  const isPremiumReward = collectible?.tier === RewardTier.PREMIUM

  return (
    <Dialog className={className} includeBanner open={open} onClose={onClose}>
      <Container>
        {
          isPremiumReward && (
            <PremiumRewardContainer>
              <Typography variant="h6">{'This is a Premium Reward!'}</Typography>
              <Typography variant="h6">{'Once you\'ve subscribed to a paid plan, it\'s all yours! 👍'}</Typography>
            </PremiumRewardContainer>
          )
        }
        <OnePassPlans subscription={user?.subscription} />
        <OnePassDialogActions>
          <Button onClick={onClose}>{'Close'}</Button>
        </OnePassDialogActions>
      </Container>
    </Dialog>
  )
})`
  ${PremiumRewardContainer} {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: ${({ theme }) => theme.spacing(2)};
    border: ${({ theme }) => `3px solid ${theme.border.primary.active}`};
    border-radius: 10px;
  }

  ${Container} {
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      align-items: center;
  }
`

export default OnePassDialog
