// ** Next Imports
import NextImage from 'next/image'

// ** MUI Imports
import Box from '@mui/material/Box'

// ** Icon imports

import SearchIcon from '@mui/icons-material/Search'
import ViewGridOutline from 'mdi-material-ui/ViewGridOutline'
import PersonIcon from '@mui/icons-material/Person'
import discordLogo from 'public/images/logos/discord.svg'
import { TelegramLogo } from '@1kit/ui/icons/TelegramLogo'
import Games from '@mui/icons-material/VideogameAsset'

function DiscordIcon() {
  return (
    <Box sx={{
      width: '24px', height: '24px', display: 'flex', justifyContent: 'center', alignItems: 'center',
    }}
    >
      <NextImage src={discordLogo} alt="discord icon" width={20} height={20} />
    </Box>
  )
}

const navigation = (user, flags) => [
  {
    title: 'Discover',
    icon: SearchIcon,
    path: '/',
  },
  {
    title: 'My Collection',
    icon: ViewGridOutline,
    path: '/my-collection',
    disabled: !user,
  },
  {
    title: 'My Profile',
    icon: PersonIcon,
    path: '/profile',
    disabled: !user,
  },
  {
    title: 'Discord',
    icon: DiscordIcon,
    openInNewTab: true,
    path: 'https://discord.gg/sqxD4PeU2s',
  },
  {
    title: 'Telegram',
    icon: TelegramLogo,
    openInNewTab: true,
    path: 'https://t.me/gr1dnetwork',
  },
  {
    title: 'Partners > Games',
    path: '/partners/games',
    icon: Games,
    hidden: (
      !user
      || user?.role === 'user'
      || !flags?.partners
    ),
  },
  {
    title: 'Partners > Rewards',
    path: '/partners/rewards',
    icon: Games,
    hidden: (
      !user
      || user?.role === 'user'
      || !flags?.partners
    ),
  },
]

export default navigation
