import { UseMutationResult } from '@1kit/ui/data'
import { usePromise } from '@1kit/ui/hooks/usePromise'
import { useRouter } from 'next/router'
import Spinner from '@1kit/ui/atoms/Spinner'
import { AuthProps } from '@1kit/types'

type Props = {
  result: UseMutationResult<Partial<AuthProps>, Error, void>
}

export default function CallbackPage({ result }: Props) {
  const {
    mutateAsync, isIdle, isSuccess, error,
  } = result
  const router = useRouter()

  usePromise(async () => {
    if (isIdle && !isSuccess) {
      const response = await mutateAsync()
      if (response.token && response.user) {
        await router.replace('/')
      }
    }
  }, [isIdle, isSuccess])

  if (error) {
    return (
      <div>
        <h1>{'Something went wrong'}</h1>
        <p>{'Please try again'}</p>
      </div>
    )
  }

  return (
    <Spinner />
  )
}
