// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

export const appLayoutManSlice = createSlice({
  name: 'LayoutMan',
  initialState: {
    hiddenMenu: false,
  },
  reducers: {
    setHiddenMenuInStore: (state, action) => {
      state.hiddenMenu = action.payload
    },
  },
})

export const { setHiddenMenuInStore } = appLayoutManSlice.actions

export default appLayoutManSlice.reducer
